import type { TableInvoice } from '@/components/billing/invoice/card/types'
import type { MappedAccountInvoice } from '@/components/account/invoices/types'
import type { TableColumn, TableRow, CellSize } from '@/components/table/types'
import { formatDate, MDY2, MD } from '@/utils/formatDate'
import { toCurrencyString } from '@/utils/formatNumbers'
import { sortDateObjects, sortDates, sortMapped, sortNumbers, sortStrings } from '@/utils/sortMethods/index'
import { TICKET_PRIORITY_MAPPING, TICKET_STATUS_MAPPING } from '@/constants/ticketing'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import type { Ref } from 'vue'

import type { SortOrder } from '@/utils/sortMethods/types'

export function useTableInvoices (invoices: Ref<MappedAccountInvoice[]>) {
	const route = useRoute()
	const mappedInvoices = computed((): TableInvoice[] => {
		return invoices.value?.map((invoice) => {
			return {
				id: invoice?.id?.toString(),
				dateRange: `${formatDate(invoice?.startDate, MD)} - ${formatDate(invoice?.endDate, MD)}`,
				amount: toCurrencyString(invoice?.totalAmount),
				balance: toCurrencyString(invoice?.amountDue),
				dueDate: formatDate(invoice?.dueDate, MDY2),
				paymentStatus: invoice?.amountDue > 0 ? 'Open' : 'Paid',
				autopayFailed: invoice?.autopayFailed ? 'Failed' : 'Not Failed',
				href: route.name === 'internal-accounts-accountId-billing'
					? `/accounts/${invoice?.accountId}/invoices/${invoice?.id}`
					: `/billing/invoices/${invoice?.id}`
			}
		})
	})

	const overdueInvoices = computed(() => {
		return mappedInvoices.value?.filter((invoice) => {
			return invoice.paymentStatus === 'Due' && new Date(invoice.dueDate) < new Date()
		})
	})

	return {
		mappedInvoices,
		overdueInvoices
	}
}

export function useTableSorting () {
	const sortTable = (sort: { column: TableColumn, activeSortColumnKey: Ref<string>, sortOrder: Ref<SortOrder>, tableData: Ref<TableRow[]> }) => {
		const { column, activeSortColumnKey, sortOrder, tableData } = sort

		const columnKey = column.key
		const columnSortable = column.sortable

		if (!columnSortable) {
			return
		}
		if (activeSortColumnKey.value === columnKey) {
			sortOrder.value = sortOrder.value === 'ASC' ? 'DESC' : 'ASC'
		} else {
			activeSortColumnKey.value = columnKey
			sortOrder.value = 'ASC'
		}

		tableData.value.sort((a, b) => {
			const valA: string | Date = a[columnKey] as string | Date
			const valB: string | Date = b[columnKey] as string | Date

			// dont explode if null values
			if (!valA || !valB) {
				return 0
			}

			// if val string is a date string sort as date
			if (typeof valA === 'string' && valA.match(/\d{4}-\d{2}-\d{2}/) && typeof valB === 'string' && valB.match(/\d{4}-\d{2}-\d{2}/)) {
				return sortDates(valA, valB, sortOrder.value)
			}

			// if date objects are passed sort as date objects
			if (typeof valA === 'object' && typeof valA?.getMonth === 'function' && typeof valB === 'object' && typeof valB?.getMonth === 'function') {
				return sortDateObjects(valA, valB, sortOrder.value)
			}

			// remove dollar signs if present
			const valAString = (typeof valA === 'string' && valA?.replace(/\$/g, '')) || valA?.toString()
			const valBString = (typeof valB === 'string' && valB?.replace(/\$/g, '')) || valB?.toString()

			const valANumber = parseFloat(valAString) || 0
			const valBNumber = parseFloat(valBString) || 0

			const valsAreNaN = () => {
				return isNaN(valANumber) || isNaN(valBNumber)
			}

			const valsAreStrings = () => {
				return !!valAString?.length && !!valBString?.length
			}

			if (columnKey as string === 'all') {
				return 0
			} else if (columnKey as string === 'status') {
				return sortMapped(a.status, b.status, sortOrder.value, TICKET_STATUS_MAPPING)
			} else if (columnKey as string === 'priority') {
				return sortMapped(a.priority, b.priority, sortOrder.value, TICKET_PRIORITY_MAPPING)
			} else if (columnKey as string === 'category') {
				return sortStrings(a.category, b.category, sortOrder.value)
			} else if ((typeof valA === 'string' && typeof valB === 'string') && (columnKey as string === 'lastUpdated' || columnKey as string === 'dueDate' || columnKey as string === 'lastDate')) {
				return sortDates(valA, valB, sortOrder.value)
			}

			const sortNumberValues = !!(valANumber && valBNumber && !valsAreNaN())

			if (sortNumberValues) {
				return sortNumbers(parseFloat(valAString), parseFloat(valBString), sortOrder.value)
			}
			if (valsAreStrings()) {
				return sortStrings(valAString, valBString, sortOrder.value)
			}

			return 0
		})
	}

	return {
		sortTable
	}
}

export const useTableSearchHighlighting = (searchTerm: Ref<string | number>) => {
	// Return a highlight function that computes the highlighted text
	const highlight = (text: string | number) => {
		return computed(() => {
			if (!text || !searchTerm.value) { return text }

			const term = searchTerm.value.toString().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&') // Escape special characters in searchTerm
			const regex = new RegExp(`(${term})`, 'gi') // Create a case-insensitive regex pattern
			const highlightedText = String(text).replace(regex, '<span class="bg-mx-orange p-1 text-white font-semibold">$1</span>')
			return highlightedText
		})
	}

	return {
		highlight
	}
}

export const useTableSizing = (tableId: string, tableCellSize: Ref<CellSize>) => {
	const cellSizeOptions: CellSize[] = [ 'sm', 'md', 'lg' ]

	const smCellSize = 'text-xs px-6 py-1.5 font-normal'
	const mdCellSize = 'text-sm px-6 py-3 font-normal'
	const lgCellSize = 'text-sm h-[72px] px-6 py-4 font-normal'

	const smHeaderCellSize = 'text-xs px-6 py-1 font-normal'
	const mdHeaderCellSize = 'text-sm px-6 py-2 font-normal'
	const lgHeaderCellSize = 'text-sm px-6 py-3 font-normal'

	const smControlBarSize = 'py-3 px-3'
	const mdControlBarSize = 'py-3 px-3'
	const lgControlBarSize = 'py-4 px-6'

	const smControlButtonSize = 'h-10'
	const mdControlButtonSize = 'h-10'
	const lgControlButtonSize = 'h-12'

	const smDropdownSize = 'top-[40px]'
	const mdDropdownSize = 'top-[40px]'
	const lgDropdownSize = 'top-[48px]'

	const smPageInputSize = 'w-12 h-8'
	const mdPageInputSize = 'w-12 h-8'
	const lgPageInputSize = 'w-12 h-8'

	const smTextSize = 'text-xs'
	const mdTextSize = 'text-sm'
	const lgTextSize = 'text-lg'

	const cellSize = computed(() : CellSize => userSelectedCellSize.value !== '' ? userSelectedCellSize.value : tableCellSize.value)

	const tableDataClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smCellSize
		case 'md':
			return mdCellSize
		case 'lg':
			return lgCellSize
		default:
			return mdCellSize
		}
	})

	const tableColumnClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smHeaderCellSize
		case 'md':
			return mdHeaderCellSize
		case 'lg':
			return lgHeaderCellSize
		default:
			return mdHeaderCellSize
		}
	})

	const tableTextClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smTextSize
		case 'md':
			return mdTextSize
		case 'lg':
			return lgTextSize
		default:
			return mdTextSize
		}
	})

	const controlButtonClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smControlButtonSize
		case 'md':
			return mdControlButtonSize
		case 'lg':
			return lgControlButtonSize
		default:
			return mdControlButtonSize
		}
	})

	const controlBarClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smControlBarSize
		case 'md':
			return mdControlBarSize
		case 'lg':
			return lgControlBarSize
		default:
			return mdControlBarSize
		}
	})

	const dropdownClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smDropdownSize
		case 'md':
			return mdDropdownSize
		case 'lg':
			return lgDropdownSize
		default:
			return mdDropdownSize
		}
	})

	const pageInputClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smPageInputSize
		case 'md':
			return mdPageInputSize
		case 'lg':
			return lgPageInputSize
		default:
			return mdPageInputSize
		}
	})

	const userSelectedCellSize: Ref<CellSize | ''> = useCookie(
		`${tableId}CellSize`,
		{ default: () => '' }
	)

	return {
		tableDataClasses,
		tableColumnClasses,
		tableTextClasses,
		controlButtonClasses,
		controlBarClasses,
		dropdownClasses,
		pageInputClasses,
		cellSize,
		cellSizeOptions,
		userSelectedCellSize
	}
}
